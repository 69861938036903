import React from 'react';

import { BylineContainer, BylineMessageContainer } from './ContentOwnershipStyles';
import { PageOwnershipBylineContent } from './PageOwnershipBylineContent';
import { PageOwnershipBylinePopupHandler } from './PageOwnershipBylinePopupHandler';
import type { UserShape } from './types';

type PageOwnershipBylineProps = {
	owner: UserShape;
	creator: UserShape;
	createdDate: Date | null;
	templateId: string | null;
	contentId: string | null;
	lastOwner: UserShape | null;
};
export const PageOwnershipByline = ({
	owner,
	creator,
	createdDate,
	templateId,
	contentId,
	lastOwner,
}: PageOwnershipBylineProps) => {
	return (
		<BylineContainer>
			<BylineMessageContainer>
				<PageOwnershipBylineContent owner={owner} templateId={templateId} />
			</BylineMessageContainer>
			<PageOwnershipBylinePopupHandler
				creator={creator}
				createdDate={createdDate}
				ownedByAccountId={owner.userId}
				contentId={contentId}
				lastOwner={lastOwner}
			/>
		</BylineContainer>
	);
};
