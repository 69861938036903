import React, { useCallback } from 'react';
import { useIntl } from 'react-intl-next';

import Button from '@atlaskit/button';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useContentOwnershipContext } from './ContentOwnershipContext';
import {
	PopupButtonContainer,
	PopupContainer,
	PopupUserDetailsContainer,
} from './ContentOwnershipStyles';
import { i18n } from './i18n';
import type { UserShape } from './types';
import { useIsAllowedToChangeOwner } from './useIsAllowedToChangeOwner';
import { useContentInfo } from './useContentInfo';

type PageOwnershipBylinePopupContentProps = {
	ownedByAccountId: string | null;
	onClosePopup: () => void;
	creator: UserShape;
	createdDate: Date | null;
	contentId: string | null;
	lastOwner: UserShape | null;
};
export const PageOwnershipBylinePopupContent = ({
	ownedByAccountId,
	onClosePopup,
	creator,
	createdDate,
	contentId,
	lastOwner,
}: PageOwnershipBylinePopupContentProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const intl = useIntl();
	const { openModal } = useContentOwnershipContext();
	const showChangeOwnerAction = useIsAllowedToChangeOwner(ownedByAccountId);
	const { isArchived } = useContentInfo({ contentId });

	const handleClick = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'change.page.owner.button',
				source: 'viewPageScreen',
				attributes: { contentId },
			},
		}).fire();
		onClosePopup();
		openModal();
	}, [onClosePopup, openModal, createAnalyticsEvent, contentId]);

	const dateOptions: Intl.DateTimeFormatOptions = {
		month: 'short',
		day: '2-digit',
		year: 'numeric',
	};

	const createdByContent = createdDate
		? intl.formatMessage(i18n.popupCreatedBy, {
				user: creator.fullName,
				date: createdDate?.toLocaleDateString(intl.locale, dateOptions),
				b: (chunks: React.ReactNode[]) => <b>{chunks}</b>,
			})
		: // In practice, this shouldn't happen
			intl.formatMessage(i18n.popupCreatedByUnknownDate, {
				user: creator.fullName,
				b: (chunks: React.ReactNode[]) => <b>{chunks}</b>,
			});

	const lastOwnedByContent = lastOwner
		? intl.formatMessage(i18n.popupLastOwnedBy, {
				user: lastOwner?.fullName,
				b: (chunks: React.ReactNode[]) => <b>{chunks}</b>,
			})
		: null;

	return (
		<PopupContainer data-testId="page-ownership-popup-container">
			{lastOwnedByContent && (
				<PopupUserDetailsContainer>{lastOwnedByContent}</PopupUserDetailsContainer>
			)}
			<PopupUserDetailsContainer>{createdByContent}</PopupUserDetailsContainer>
			{showChangeOwnerAction && (
				<PopupButtonContainer>
					<Button
						testId="page-owner-popup-change-owner-button"
						appearance="primary"
						onClick={handleClick}
						isDisabled={isArchived}
					>
						{intl.formatMessage(i18n.popupChangeOwnerButton)}
					</Button>
				</PopupButtonContainer>
			)}
		</PopupContainer>
	);
};
