import type { FC } from 'react';
import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { styled, css } from '@compiled/react';
import { FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { N400, N500 } from '@atlaskit/theme/colors';

import {
	EXTENSION_POINT_BYLINE_EXPERIENCE,
	ExperienceStart,
	ExperienceSuccess,
} from '@confluence/experience-tracker';
import { WebItemList } from '@confluence/web-item-list';
import { RequireLegacyWRM } from '@confluence/wrm';
import type { ForgeUIContentBylineItemExtensionType } from '@confluence/forge-ui';
import { FORGE_MODULE_BYLINE, LazyForgeUIExtensions } from '@confluence/forge-ui';
import {
	confluenceLocalStorageInstance as localStorage,
	keys as localStorageKeys,
} from '@confluence/storage-manager';
import {
	WebItemLocation,
	SYSTEM_CONTENT_BYLINE,
	type WebItemLocationChildrenFnWithLoading,
} from '@confluence/web-item-location';
import { ErrorDisplay } from '@confluence/error-boundary';
import { isErrorMarkedAsHandled } from '@confluence/graphql';

import { ByLineAppsPlaceholder } from './ByLineAppsPlaceholder';
import { ByLineForgeApp } from './ByLineForgeApp';

const NUMBER_OF_VISIBLE_APPS = 3;

// eslint-disable-next-line @compiled/no-exported-css, @compiled/no-css-tagged-template-expression, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
export const dot = css`
	content: '•';
	display: inline-block;
	color: ${token('color.text.subtle', 'grey')};
	padding: 0 ${token('space.100', '8px')} 0 0;
	text-decoration: none;
	/* for IE <= 11 */
`;

// eslint-disable-next-line @compiled/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const StyledWebItemList = styled(WebItemList)<typeof WebItemList>`
	padding: 0;
	margin: 0 ${token('space.050', '4px')} 0 0;
	line-height: 10px;

	& .bylineWebItem,
	& .bylineWebItem:visited {
		margin: 0;
		line-height: 1.5;
		display: inline-block;
		color: ${token('color.text.subtle', N500)};
	}

	& .bylineWebItem img {
		vertical-align: text-bottom;
	}

	& .bylineWebItem.with-dot:before {
		${dot};
	}

	& .bylineWebItem:hover {
		color: ${token('color.text.subtlest', N400)};
	}

	& .bylineWebItem:hover:before {
		/* for IE <= 11 */
		text-decoration: none;
		color: ${token('color.text.subtlest', N400)};
	}
`;

// eslint-disable-next-line @compiled/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const ShowMoreLess = styled.span`
	display: inline-block;
	color: ${token('color.text.subtle', N500)};
	cursor: pointer;

	& :before {
		${dot};
	}
`;

type ByLineAllAppsProps = {
	contentId: string;
	connectItems: object[];
	forgeItems: ReadonlyArray<ForgeUIContentBylineItemExtensionType>;
	onLoad: () => void;
	showLivePagesExtensionPoints?: boolean;
};

const ByLineAllApps: FC<ByLineAllAppsProps> = ({
	contentId,
	connectItems,
	forgeItems,
	onLoad,
	showLivePagesExtensionPoints,
}) => {
	const [isShowAll, setShowAll] = useState(
		localStorage.getItemAsBoolean(localStorageKeys.BYLINE_APPS_SHOW_ALL),
	);

	useEffect(() => {
		localStorage.setItem(localStorageKeys.BYLINE_APPS_SHOW_ALL, isShowAll);
	}, [isShowAll]);

	useEffect(() => {
		onLoad();
	}, [onLoad]);

	connectItems = connectItems || [];
	forgeItems = forgeItems || [];

	const renderPart = (cApps, fApps, numberOfForgeAppsToShow) => (
		<Fragment>
			{cApps.length ? (
				<StyledWebItemList
					pageId={contentId}
					webItems={cApps}
					ContainerTagName="span"
					webItemClassName="bylineWebItem"
					hidePrecedingDot={showLivePagesExtensionPoints}
				/>
			) : null}
			{fApps.map((app, idx) => (
				<ByLineForgeApp
					key={app.id}
					app={app}
					contentId={contentId}
					isHidden={isShowAll ? false : idx >= numberOfForgeAppsToShow}
					setShowAll={setShowAll}
					// If live page extensions are enabled and the page has no byline connect apps, hide the dot for the first Forge app.
					hideDot={showLivePagesExtensionPoints && cApps.length === 0 && idx === 0}
				/>
			))}
		</Fragment>
	);

	if (connectItems.length + forgeItems.length <= NUMBER_OF_VISIBLE_APPS) {
		return renderPart(connectItems, forgeItems, forgeItems.length);
	} else {
		// We should show the first NUMBER_OF_VISIBLE_APPS apps including Connect and Forge app;
		// however, all Forge apps need to be RENDERED in order for the Forge Keyboard Shortcut listener
		// to be rendered and active. If (Connect app + Forge apps) is greater than NUMBER_OF_VISIBLE_APPS,
		// then we will show the first NUMBER_OF_VISIBLE_APPS Connect and Forge apps, render all the
		// Forge apps, but visually hide the Forge apps that go beyond NUMBER_OF_VISIBLE_APPS.
		const numberOfConnectAppsToRender = Math.min(connectItems.length, NUMBER_OF_VISIBLE_APPS);
		const numberOfForgeAppsToShow = NUMBER_OF_VISIBLE_APPS - numberOfConnectAppsToRender;

		const firstConnectApps = connectItems.slice(0, numberOfConnectAppsToRender);
		const remainingConnectApps = connectItems.slice(numberOfConnectAppsToRender);

		return (
			<Fragment>
				{renderPart(firstConnectApps, forgeItems, numberOfForgeAppsToShow)}
				{isShowAll ? (
					<Fragment>
						{renderPart(remainingConnectApps, [], 0)}
						<ShowMoreLess onClick={() => setShowAll(false)} data-testid="byline-show-less">
							<FormattedMessage
								id="content-header.by-line.show-less"
								defaultMessage="Show less"
								description="The link to show less apps"
							/>
						</ShowMoreLess>
					</Fragment>
				) : (
					<ShowMoreLess onClick={() => setShowAll(true)} data-testid="byline-show-more">
						<FormattedMessage
							id="content-header.by-line.show-more"
							defaultMessage="Show more"
							description="The link to show more apps"
						/>
					</ShowMoreLess>
				)}
			</Fragment>
		);
	}
};

type ByLineAppsProps = {
	contentId: string;
	showLivePagesExtensionPoints?: boolean;
};

export const ByLineApps: FC<ByLineAppsProps> = ({ contentId, showLivePagesExtensionPoints }) => {
	const [showLoader, setShowLoader] = useState(true);
	const onLoad = useCallback(() => setShowLoader(false), []);

	return (
		<Fragment>
			<ExperienceStart name={EXTENSION_POINT_BYLINE_EXPERIENCE} />
			{showLoader ? (
				<ByLineAppsPlaceholder
					key="byline-apps-loading"
					testId="byline-apps-loading"
					showLivePagesExtensionPoints={showLivePagesExtensionPoints}
				/>
			) : null}
			<RequireLegacyWRM
				key="byline-app-wrm"
				wrm="wr!com.atlassian.plugins.atlassian-connect-plugin:confluence-atlassian-connect-content-byline-resources-v5"
			>
				{() => (
					<WebItemLocation
						tagName="span"
						contentId={contentId}
						location={SYSTEM_CONTENT_BYLINE}
						renderWhenLoading
					>
						{
							(({ webItems: connectItems, loading: loadingConnectApps }) => {
								if (loadingConnectApps) {
									// When loadingConnectApps changes from 'true' to 'false', it causes this function to re-run and
									// therefore re-mount the 'LazyForgeUIExtensions'. To avoid it we don't render 'LazyForgeUIExtensions'
									// while 'loadingConnectApps' is 'true'.
									// TODO(rtoropov): This will cause a delay rendering Forge apps since their load will trigger with
									//  first mount of 'LazyForgeUIExtensions'. Need an alternative way to fix a re-mount of the latter.
									return null;
								}
								return (
									<LazyForgeUIExtensions
										// Set 'key' as a contentId to force LazyForgeUIExtensions to be re-mounted when contentId changes.
										// This makes sure that old component state is not re-used for a new content id, because the list of
										// Forge extensions needs to be processed against display conditions that may depend on content
										// properties.
										key={contentId}
										moduleType={FORGE_MODULE_BYLINE}
										render={(forgeItems, loadingForgeApps, error) => {
											if (loadingForgeApps) return null;

											if (error) {
												if (!isErrorMarkedAsHandled(error)) {
													return <ErrorDisplay error={error} />;
												}
												// Process handled errors as successful
												return <ExperienceSuccess name={EXTENSION_POINT_BYLINE_EXPERIENCE} />;
											}

											return (
												<>
													<ByLineAllApps
														contentId={contentId}
														forgeItems={forgeItems}
														connectItems={connectItems}
														onLoad={onLoad}
														showLivePagesExtensionPoints={showLivePagesExtensionPoints}
													/>
													<ExperienceSuccess name={EXTENSION_POINT_BYLINE_EXPERIENCE} />
												</>
											);
										}}
									/>
								);
							}) as WebItemLocationChildrenFnWithLoading
						}
					</WebItemLocation>
				)}
			</RequireLegacyWRM>
		</Fragment>
	);
};
